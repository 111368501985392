
function recordEvent(timer, period, events, setEvents, eventType) {
    console.log('tring to recordEvent');
    setEvents([...events, { period: period, time: fullShort(timer), eventType: eventType }]);
}

function fullShort(timer) {
    if (timer < 60) {
        return formatSeconds(timer, 2);
    } else {
        return formatSeconds(timer, 0);
    }
}

function formatSeconds(seconds, decimalPlaces = 0) {

    // show different times depending on "stuff"
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format minutes and seconds with leading zeros
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    // If decimal places are required, calculate the fractional part
    if (decimalPlaces > 0) {
        // const fractionalSeconds = (remainingSeconds - Math.floor(remainingSeconds)).toFixed(decimalPlaces);
        // return `${formattedMinutes}:${formattedSeconds}${fractionalSeconds.substring(1)}`;
        const fractionalSeconds = Math.floor((seconds - Math.floor(seconds)) * (10 ** decimalPlaces));
        const formattedfractionalSeconds = String(fractionalSeconds).padStart(decimalPlaces, '0');


        // \?).toFixed(decimalPlaces);
        return `${formattedMinutes}:${formattedSeconds}.${formattedfractionalSeconds}`;
    } else {
        return `${formattedMinutes}:${formattedSeconds}`;
    }
}

export default recordEvent 