import React, { createContext, useState } from 'react';
import generateGameID from '../GenerateGameID';



export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    //   const [state, setState] = useState({ user: null, theme: 'light' });
    const [FLAGS, setFlags] = useState(() => {
        const savedFlags = JSON.parse(localStorage.getItem("hockeyData.flags")) || {};
        // change this, make savedFlags be the parse,

        const defaultFlags = {
            debug: true,
            editEvent: true,
            uploadData: false,
            trackMisses: false,
            multiGoalie: true,
            penalties: false,
            clockMainAdjust: true,
            timerActive: true
        };
        // then filter to keep only the valid keys, and to add any missing flags
        //  only keep flags that exist in defaultFlags
        const updatedSaveFlags = {};
        Object.keys(defaultFlags).forEach(key => {
            if (key in savedFlags) { updatedSaveFlags[key] = savedFlags[key]; }
            else { updatedSaveFlags[key] = defaultFlags[key]; }
        });
        return updatedSaveFlags;
    });

    // time is in hundredths of a second
    const [timer, setTimer] = useState(600);
    const [running, setRunning] = useState(false);
    const [events, setEvents] = useState(() => {
        const savedEvents = localStorage.getItem("hockeyData.events");
        if (FLAGS.debug) { console.log("loading events") }
        return savedEvents !== null ? JSON.parse(savedEvents) : []
    });
    const [period, setPeriod] = useState("1st");
    // pull counters from the hockeydata if it exists
    const [counters, setCounters] = useState(() => {
        const savedCount = localStorage.getItem("hockeyData.counters");
        if (FLAGS.debug) { console.log("loading hockeydata"); }
        return savedCount !== null ? JSON.parse(savedCount) :
            {
                HomeGoal: 0,
                VisitorShot: 0,
                HomeShot: 0,
                VisitorGoal: 0,
                HomeMiss: 0,
                VisitorMiss: 0
            }
    });
    const [goalies, setGoalies] = useState(() => {
        const savedCount = localStorage.getItem("hockeyData.goalies");
        if (FLAGS.debug) { console.log("loading goalies"); }
        return savedCount !== null ? JSON.parse(savedCount) :
            { home: 'Default', visitor: 'Default' }
    });

    const [teams, setTeams] = useState(() => {
        const savedTeams = JSON.parse(localStorage.getItem("hockeyData.teams")) || {
            homeShortName: '-',
            visitorShortName: '-',
            homeAgeDivision: '-',
            visitorAgeDivision: '-'
        };
        return (savedTeams)
    })
    const [theme, setTheme] = useState(() => {
        const savedTeams = JSON.parse(localStorage.getItem("hockeyData.theme")) || "default";
        return (savedTeams)
    })
    const [gameID, setGameID] = useState(() => {
        const gameID = JSON.parse(localStorage.getItem("hockeyData.gameID")) || generateGameID();
        return (gameID)
    })



    return (
        <AppContext.Provider value={{
            FLAGS, setFlags, timer, setTimer, running, setRunning, events, setEvents,
            period, setPeriod, counters, setCounters, goalies, setGoalies, teams, setTeams
            , theme, setTheme
            , gameID, setGameID
        }}>
            {children}
        </AppContext.Provider>
    );
};
